const ROUTES = {
  "/": {
    "name": "HomePage55b17fdf5368485cA4dfC81a5e4ccfed",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages805f517a2e044b42Bc2e3bebb0b4741b",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage324587ebA7db40988d9aA34fcbd5a18e",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageE967d8c724e64625Af4a0d2ea78d7a8a",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPage3882e5e0F9a749e0Bed51e8867aab018",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageA36441a8Eecc498cA9787a3e3f2f698a",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages621bd3ee44174c6080f28e9a8cce5f23",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages96b75e7cF4ce43f7A580A3d19425300b",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage0d95b6d03675449d8dcf382036c93f6a",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages2010b3e399ca4c7dAf051c90c66f99cd",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;
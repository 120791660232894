import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "19d764af-fef1-4655-a08c-032a19f69b63"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards19d764afFef14655A08c032a19f69b63(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards19d764afFef14655A08c032a19f69b63", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" postSlug="home-page-posts2" {...props} />
  );
}

// id: "55b17fdf-5368-485c-a4df-c81a5e4ccfed"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage55b17fdf5368485cA4dfC81a5e4ccfed(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage55b17fdf5368485cA4dfC81a5e4ccfed page`}>
      {props["header"] || <HomeHeader670e8f4bA00447bc936dF6b6edad028d parentTag="Header2e7f351c2da9418eA1bd0eca05b2fa25" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack31ea10e2E9a04817A81a5b88a4354dd9", parentTag)} {...props} />}
      {props["body"] || <MarketingBody5a5c272e306d4d2a9c4051a0ca5cf2de parentTag="BodyF8af7e59325f42c68556Fd472f5a96fd" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("QuoteB77ab5b9E6494c2cA39e9430bf6c5ffe", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="Footer24d2ed2b543f4ed6847a6a537b6c126c" {...props} />}
    </div>
  );
}

// id: "791bd59f-378d-4bed-a19a-82229ae11ce4"
// title: ""
// type: :reference
// key: "header"
// parent_id: "621bd3ee-4417-4c60-80f2-8e9a8cce5f23"
export function Header791bd59f378d4bedA19a82229ae11ce4(props: any) {
  return (
    <MembersAreaHeaderE622cfd71d7240399e3aC7ef2f3fcc0c parentTag="Header791bd59f378d4bedA19a82229ae11ce4" {...props} />
  );
}

// id: "b4189e5d-0573-4c19-98da-b733fc8e9503"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "670e8f4b-a004-47bc-936d-f6b6edad028d"
export function HeroB4189e5d05734c1998daB733fc8e9503(props: any) {
  return (
    <HomePageHero8b5ee7897d9746cfA7dcB226afdd042f parentTag="HeroB4189e5d05734c1998daB733fc8e9503" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "749c4a1f-edad-47d6-adcf-83a7944f1c55"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "9bbf9896-3936-47e3-8146-057d38e5d5fb"
export function Copyright749c4a1fEdad47d6Adcf83a7944f1c55(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright749c4a1fEdad47d6Adcf83a7944f1c55", parentTag)} {...props} />
  );
}

// id: "d389f6f8-5c4b-42a9-8d7e-f30384fccea2"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "1e69228a-ca1d-475f-a948-cfa7b5989cc4"
export function FooterD389f6f85c4b42a98d7eF30384fccea2(props: any) {
  return (
    <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="FooterD389f6f85c4b42a98d7eF30384fccea2" {...props} />
  );
}

// id: "b7665883-a756-433d-a866-a4844be893ad"
// title: ""
// type: :reference
// key: "header"
// parent_id: "0d95b6d0-3675-449d-8dcf-382036c93f6a"
export function HeaderB7665883A756433dA866A4844be893ad(props: any) {
  return (
    <SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="HeaderB7665883A756433dA866A4844be893ad" title="Resources" {...props} />
  );
}

// id: "365be48c-281c-494c-b82e-65521475df47"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "9bbf9896-3936-47e3-8146-057d38e5d5fb"
export function Groupflow365be48c281c494cB82e65521475df47(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow365be48c281c494cB82e65521475df47", parentTag)} {...props} />
  );
}

// id: "b7d4077d-3e2e-4bec-9d1d-fca696a6f354"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "b268625f-3011-4ad1-badf-fcdf26d15be9"
export const WidgetIdB7d4077d3e2e4bec9d1dFca696a6f354 = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "479ae2d1-e576-44e1-a1f3-7cc0c39534dd"
// title: ""
// type: :text
// key: "title"
// parent_id: "ec073b4c-ce4b-4ca6-87e9-c6a63c3e26b0"
export const Title479ae2d1E57644e1A1f37cc0c39534dd = "Secondary Page";

// id: "e773c6c3-f104-4e54-a90a-e9d40a5f3cef"
// title: ""
// type: :text
// key: "title"
// parent_id: "4fc8ac3a-ec62-4537-8e40-a3c7307410e4"
export const TitleE773c6c3F1044e54A90aE9d40a5f3cef = "Join Us";

// id: "2e7f351c-2da9-418e-a1bd-0eca05b2fa25"
// title: ""
// type: :reference
// key: "header"
// parent_id: "55b17fdf-5368-485c-a4df-c81a5e4ccfed"
export function Header2e7f351c2da9418eA1bd0eca05b2fa25(props: any) {
  return (
    <HomeHeader670e8f4bA00447bc936dF6b6edad028d parentTag="Header2e7f351c2da9418eA1bd0eca05b2fa25" {...props} />
  );
}

// id: "7eb10533-2981-48ec-858f-20121926a5ca"
// title: ""
// type: :reference
// key: "header"
// parent_id: "96b75e7c-f4ce-43f7-a580-a3d19425300b"
export function Header7eb10533298148ec858f20121926a5ca(props: any) {
  return (
    <MembersAreaHeaderE622cfd71d7240399e3aC7ef2f3fcc0c parentTag="Header7eb10533298148ec858f20121926a5ca" {...props} />
  );
}

// id: "7ba68e65-0362-4d8a-9bb0-5c51bc9201ec"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "18486a18-903c-470f-90fe-5a359d804203"
export function LinkedLogo7ba68e6503624d8a9bb05c51bc9201ec(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo7ba68e6503624d8a9bb05c51bc9201ec", parentTag)} label={<DefaultLogo9e3af418Fcce4efe8b055f06ecdab7e0 parentTag="LabelFfced2f54c984bacA9028bd592041ed3" alt="logo" />} {...props} />
  );
}

// id: "f7b680a2-b335-4b3f-bae1-dd9003cc561a"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "18486a18-903c-470f-90fe-5a359d804203"
export function NavigationF7b680a2B3354b3fBae1Dd9003cc561a(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationF7b680a2B3354b3fBae1Dd9003cc561a", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "56b86aca-51dd-4686-a256-771844a3b750"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "9be75641-ddb7-4489-ac03-9be0fa4ab7f9"
export const NavMenuSlug56b86aca51dd4686A256771844a3b750 = "marketing-primary-nav";

// id: "969e63d5-5488-4770-8854-f50e8929ae56"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "7d0f816a-a16f-45ec-89b6-5f258df7e190"
export const NavMenuSlug969e63d5548847708854F50e8929ae56 = "members-primary-nav";

// id: "855d986b-165b-4e38-8ebb-b521555bbe28"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "8c6f93cd-ad41-4711-9513-27b38eb8acb3"
export const NavMenuSlug855d986b165b4e388ebbB521555bbe28 = "footer-nav";

// id: "2f6c3458-43df-4538-8ebb-a7e964f51f14"
// title: ""
// type: :text
// key: "title"
// parent_id: "be28f811-c2d0-4e9c-9ffa-e69074b809c2"
export const Title2f6c345843df45388ebbA7e964f51f14 = "Leveling";

// id: "670e8f4b-a004-47bc-936d-f6b6edad028d"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader670e8f4bA00447bc936dF6b6edad028d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader670e8f4bA00447bc936dF6b6edad028d site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 parentTag="HeaderTopB268625f30114ad1BadfFcdf26d15be9" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHero8b5ee7897d9746cfA7dcB226afdd042f parentTag="HeroB4189e5d05734c1998daB733fc8e9503" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

// id: "c4b60f40-358f-4b52-8d83-272df8619aff"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "324587eb-a7db-4098-8d9a-a34fcbd5a18e"
export function FooterC4b60f40358f4b528d83272df8619aff(props: any) {
  return (
    <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="FooterC4b60f40358f4b528d83272df8619aff" {...props} />
  );
}

// id: "eba928c0-aab1-445a-a829-877e57da6136"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "ad4890a7-ac87-4acd-939d-ad759e9410b4"
export function LinkedLogoEba928c0Aab1445aA829877e57da6136(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoEba928c0Aab1445aA829877e57da6136", parentTag)} url="/" label={<DefaultLogo9e3af418Fcce4efe8b055f06ecdab7e0 parentTag="Label251de3bc648240ad99744ae2d1887dea" alt="logo" />} className="linked-logo" {...props} />
  );
}

// id: "f297a151-9076-4aa1-a27c-0bdcba56e9a4"
// title: ""
// type: :text
// key: "title"
// parent_id: "9be75641-ddb7-4489-ac03-9be0fa4ab7f9"
export const TitleF297a15190764aa1A27c0bdcba56e9a4 = null;

// id: "88ebf6a6-0cab-42bf-8411-84e61b48bee3"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "670e8f4b-a004-47bc-936d-f6b6edad028d"
export const BackgroundImage88ebf6a60cab42bf841184e61b48bee3 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "7d0f816a-a16f-45ec-89b6-5f258df7e190"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "e622cfd7-1d72-4039-9e3a-c7ef2f3fcc0c"
export function HeaderTop7d0f816aA16f45ec89b65f258df7e190(props: any) {
  return (
    <DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 parentTag="HeaderTop7d0f816aA16f45ec89b65f258df7e190" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "b77ab5b9-e649-4c2c-a39e-9430bf6c5ffe"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "55b17fdf-5368-485c-a4df-c81a5e4ccfed"
export function QuoteB77ab5b9E6494c2cA39e9430bf6c5ffe(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("QuoteB77ab5b9E6494c2cA39e9430bf6c5ffe", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "a718045d-3133-4ded-ba57-fdae18874478"
// title: ""
// type: :text
// key: "title"
// parent_id: "823fa778-b91c-459d-929c-53439da4107f"
export const TitleA718045d31334dedBa57Fdae18874478 = "Public Events";

// id: "3d6d2253-d73d-4553-ab72-90d8c4a730fd"
// title: ""
// type: :text
// key: "title"
// parent_id: "55c4c59e-f925-4648-ad40-86f86966e638"
export const Title3d6d2253D73d4553Ab7290d8c4a730fd = "Secondary Marketing Title 2";

// id: "ffced2f5-4c98-4bac-a902-8bd592041ed3"
// title: ""
// type: :reference
// key: "label"
// parent_id: "7ba68e65-0362-4d8a-9bb0-5c51bc9201ec"
export function LabelFfced2f54c984bacA9028bd592041ed3(props: any) {
  return (
    <DefaultLogo9e3af418Fcce4efe8b055f06ecdab7e0 parentTag="LabelFfced2f54c984bacA9028bd592041ed3" {...props} />
  );
}

// id: "805f517a-2e04-4b42-bc2e-3bebb0b4741b"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages805f517a2e044b42Bc2e3bebb0b4741b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages805f517a2e044b42Bc2e3bebb0b4741b`}>
      {props.children}
    </div>
  );
}

// id: "70c8e0f7-789d-487a-87bd-9c211e71107b"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label70c8e0f7789d487a87bd9c211e71107b(props: any) {
  return (
    <DefaultLogo9e3af418Fcce4efe8b055f06ecdab7e0 parentTag="Label70c8e0f7789d487a87bd9c211e71107b" {...props} />
  );
}

// id: "c4b60145-541a-4544-b0b3-03c31be84010"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const TitleC4b60145541a4544B0b303c31be84010 = "Leveling";

// id: "0d95b6d0-3675-449d-8dcf-382036c93f6a"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage0d95b6d03675449d8dcf382036c93f6a(props: any) {
  return (
    <SecondaryPage1e69228aCa1d475fA948Cfa7b5989cc4 parentTag="ResourcesPage0d95b6d03675449d8dcf382036c93f6a" header={<SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="HeaderB7665883A756433dA866A4844be893ad" title="Resources" />} {...props} />
  );
}

// id: "2010b3e3-99ca-4c7d-af05-1c90c66f99cd"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages2010b3e399ca4c7dAf051c90c66f99cd(props: any) {
  return (
    <SecondaryPage1e69228aCa1d475fA948Cfa7b5989cc4 parentTag="SignupPages2010b3e399ca4c7dAf051c90c66f99cd" header={<SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="HeaderF21eb820Eeaf4af09ae9Df26bf4e8a85" title="Join Us" />} {...props} />
  );
}

// id: "96b75e7c-f4ce-43f7-a580-a3d19425300b"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages96b75e7cF4ce43f7A580A3d19425300b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages96b75e7cF4ce43f7A580A3d19425300b`}>
      {props["header"] || <MembersAreaHeaderE622cfd71d7240399e3aC7ef2f3fcc0c parentTag="Header7eb10533298148ec858f20121926a5ca" {...props} />}
      {props.children}
    </div>
  );
}

// id: "55c4c59e-f925-4648-ad40-86f86966e638"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "13467bbe-b701-4636-9480-940f3eef2555"
export function Hero55c4c59eF9254648Ad4086f86966e638(props: any) {
  return (
    <SecondaryMarketingHero61750d6a631f42a09030F72ac0218e5f parentTag="Hero55c4c59eF9254648Ad4086f86966e638" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "24d2ed2b-543f-4ed6-847a-6a537b6c126c"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "55b17fdf-5368-485c-a4df-c81a5e4ccfed"
export function Footer24d2ed2b543f4ed6847a6a537b6c126c(props: any) {
  return (
    <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="Footer24d2ed2b543f4ed6847a6a537b6c126c" {...props} />
  );
}

// id: "be28f811-c2d0-4e9c-9ffa-e69074b809c2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e967d8c7-24e6-4625-af4a-0d2ea78d7a8a"
export function HeaderBe28f811C2d04e9c9ffaE69074b809c2(props: any) {
  return (
    <SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="HeaderBe28f811C2d04e9c9ffaE69074b809c2" title="Leveling" {...props} />
  );
}

// id: "0a83dafb-347d-4c2b-8dcc-797ef976d002"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "5a5c272e-306d-4d2a-9c40-51a0ca5cf2de"
export function MarketingCards0a83dafb347d4c2b8dcc797ef976d002(props: any) {
  return (
    <MarketingCards19d764afFef14655A08c032a19f69b63 parentTag="MarketingCards0a83dafb347d4c2b8dcc797ef976d002" postSlug="home-page-posts2" {...props} />
  );
}

// id: "41d7c264-c8a0-4fff-8425-a823412432c9"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "13467bbe-b701-4636-9480-940f3eef2555"
export function HeaderTop41d7c264C8a04fff8425A823412432c9(props: any) {
  return (
    <DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 parentTag="HeaderTop41d7c264C8a04fff8425A823412432c9" {...props} />
  );
}

// id: "c5c2d5d7-b00c-4693-adb8-946345203112"
// title: ""
// type: :text
// key: "alt"
// parent_id: "9e3af418-fcce-4efe-8b05-5f06ecdab7e0"
export const AltC5c2d5d7B00c4693Adb8946345203112 = "logo";

// id: "b268625f-3011-4ad1-badf-fcdf26d15be9"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "670e8f4b-a004-47bc-936d-f6b6edad028d"
export function HeaderTopB268625f30114ad1BadfFcdf26d15be9(props: any) {
  return (
    <DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 parentTag="HeaderTopB268625f30114ad1BadfFcdf26d15be9" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "108e0bd8-dcaf-4fbe-ac03-61cddc7ba9a5"
// title: ""
// type: :text
// key: "class"
// parent_id: "82af25a5-9e47-47e9-a53e-6109a116c8be"
export const Class108e0bd8Dcaf4fbeAc0361cddc7ba9a5 = "navigation";

// id: "94c344d3-4511-487a-abc2-20ea52531648"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "82af25a5-9e47-47e9-a53e-6109a116c8be"
export const NavMenuSlug94c344d34511487aAbc220ea52531648 = "marketing-primary-nav";

// id: "4d3941ca-d92e-40a6-bb18-d1ba66b0364b"
// title: ""
// type: :text
// key: "title"
// parent_id: "f21eb820-eeaf-4af0-9ae9-df26bf4e8a85"
export const Title4d3941caD92e40a6Bb18D1ba66b0364b = "Join Us";

// id: "9be75641-ddb7-4489-ac03-9be0fa4ab7f9"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader9be75641Ddb74489Ac039be0fa4ab7f9(props: any) {
  return (
    <ShortHeader18486a18903c470f90fe5a359d804203 parentTag="DefaultHeader9be75641Ddb74489Ac039be0fa4ab7f9" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "621bd3ee-4417-4c60-80f2-8e9a8cce5f23"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages621bd3ee44174c6080f28e9a8cce5f23(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages621bd3ee44174c6080f28e9a8cce5f23 gf-page-layout`}>
      {props["header"] || <MembersAreaHeaderE622cfd71d7240399e3aC7ef2f3fcc0c parentTag="Header791bd59f378d4bedA19a82229ae11ce4" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav6840fc939fc649a6A05303d9151ca68d", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="FooterF6c9d230D6b047a4967fC62c3c72448a" {...props} />}
    </div>
  );
}

// id: "c953435f-0977-44d4-8afe-47bc1d58ee59"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderC953435f097744d48afe47bc1d58ee59(props: any) {
  return (
    <MembersAreaHeaderE622cfd71d7240399e3aC7ef2f3fcc0c parentTag="HeaderC953435f097744d48afe47bc1d58ee59" {...props} />
  );
}

// id: "e622cfd7-1d72-4039-9e3a-c7ef2f3fcc0c"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeaderE622cfd71d7240399e3aC7ef2f3fcc0c(props: any) {
  return (
    <ShortHeader18486a18903c470f90fe5a359d804203 parentTag="MembersAreaHeaderE622cfd71d7240399e3aC7ef2f3fcc0c" header-top={<DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 parentTag="HeaderTop7d0f816aA16f45ec89b65f258df7e190" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "9e3af418-fcce-4efe-8b05-5f06ecdab7e0"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo9e3af418Fcce4efe8b055f06ecdab7e0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogo9e3af418Fcce4efe8b055f06ecdab7e0 logo`} alt="logo" />
  );
}

// id: "5a5c272e-306d-4d2a-9c40-51a0ca5cf2de"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody5a5c272e306d4d2a9c4051a0ca5cf2de(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody5a5c272e306d4d2a9c4051a0ca5cf2de page-body`}>
      {props["marketingCards"] || <MarketingCards19d764afFef14655A08c032a19f69b63 parentTag="MarketingCards0a83dafb347d4c2b8dcc797ef976d002" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "8c0380c0-8607-42ac-97ba-b186e4b3daf7"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote8c0380c0860742ac97baB186e4b3daf7(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote8c0380c0860742ac97baB186e4b3daf7`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "3882e5e0-f9a7-49e0-bed5-1e8867aab018"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage3882e5e0F9a749e0Bed51e8867aab018(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage3882e5e0F9a749e0Bed51e8867aab018`}>
      {props["header"] || <SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="Header823fa778B91c459d929c53439da4107f" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="FooterEb10f7d3A3654f88A204447977060110" {...props} />}
    </div>
  );
}

// id: "324587eb-a7db-4098-8d9a-a34fcbd5a18e"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage324587ebA7db40988d9aA34fcbd5a18e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage324587ebA7db40988d9aA34fcbd5a18e page`}>
      {props["header"] || <SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="HeaderFd40249fBcdf41efA4dd3714b898ae45" title="About Us" {...props} />}
      {props["body"] || <MarketingBody5a5c272e306d4d2a9c4051a0ca5cf2de parentTag="Body440adf96C3b84ac7B2dcAde2cdb8564b" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="FooterC4b60f40358f4b528d83272df8619aff" {...props} />}
    </div>
  );
}

// id: "a36441a8-eecc-498c-a978-7a3e3f2f698a"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageA36441a8Eecc498cA9787a3e3f2f698a(props: any) {
  return (
    <SecondaryPage1e69228aCa1d475fA948Cfa7b5989cc4 parentTag="JoinUsPageA36441a8Eecc498cA9787a3e3f2f698a" header={<SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="Header4fc8ac3aEc6245378e40A3c7307410e4" title="Join Us" />} {...props} />
  );
}

// id: "0c6ee500-df2d-44fe-9b9a-25dc5e02b0fd"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault0c6ee500Df2d44fe9b9a25dc5e02b0fd(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault0c6ee500Df2d44fe9b9a25dc5e02b0fd page`}>
      {props["header"] || <MembersAreaHeaderE622cfd71d7240399e3aC7ef2f3fcc0c parentTag="Header77c394f6B78f4120Aea627fca9e1a873" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="Footer66d6d55307e245c49eff45601938a564" {...props} />}
    </div>
  );
}

// id: "7392c0c4-3d9c-4a2d-bb15-75fbece0d063"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href7392c0c43d9c4a2dBb1575fbece0d063 = "/";

// id: "ad4890a7-ac87-4acd-939d-ad759e9410b4"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoEba928c0Aab1445aA829877e57da6136", parentTag)} url="/" label={<DefaultLogo9e3af418Fcce4efe8b055f06ecdab7e0 parentTag="Label251de3bc648240ad99744ae2d1887dea" alt="logo" />} className="linked-logo" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation82af25a59e4747e9A53e6109a116c8be", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "f8af7e59-325f-42c6-8556-fd472f5a96fd"
// title: ""
// type: :reference
// key: "body"
// parent_id: "55b17fdf-5368-485c-a4df-c81a5e4ccfed"
export function BodyF8af7e59325f42c68556Fd472f5a96fd(props: any) {
  return (
    <MarketingBody5a5c272e306d4d2a9c4051a0ca5cf2de parentTag="BodyF8af7e59325f42c68556Fd472f5a96fd" {...props} />
  );
}

// id: "5d0bbaed-9274-4110-87b9-914645e10233"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "18486a18-903c-470f-90fe-5a359d804203"
export const BackgroundImage5d0bbaed9274411087b9914645e10233 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "fd40249f-bcdf-41ef-a4dd-3714b898ae45"
// title: ""
// type: :reference
// key: "header"
// parent_id: "324587eb-a7db-4098-8d9a-a34fcbd5a18e"
export function HeaderFd40249fBcdf41efA4dd3714b898ae45(props: any) {
  return (
    <SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="HeaderFd40249fBcdf41efA4dd3714b898ae45" title="About Us" {...props} />
  );
}

// id: "1e69228a-ca1d-475f-a948-cfa7b5989cc4"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage1e69228aCa1d475fA948Cfa7b5989cc4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage1e69228aCa1d475fA948Cfa7b5989cc4`}>
      {props["header"] || <SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="HeaderEc073b4cCe4b4ca687e9C6a63c3e26b0" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="FooterD389f6f85c4b42a98d7eF30384fccea2" {...props} />}
    </div>
  );
}

// id: "f5a09dca-98a5-42b8-b563-f864ceaf59e3"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterF5a09dca98a542b8B563F864ceaf59e3(props: any) {
  return (
    <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="FooterF5a09dca98a542b8B563F864ceaf59e3" {...props} />
  );
}

// id: "e967d8c7-24e6-4625-af4a-0d2ea78d7a8a"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageE967d8c724e64625Af4a0d2ea78d7a8a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageE967d8c724e64625Af4a0d2ea78d7a8a`}>
      {props["header"] || <SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="HeaderBe28f811C2d04e9c9ffaE69074b809c2" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="Footer5a16935bE327487f969381f9d45f8761" {...props} />}
    </div>
  );
}

// id: "8c6f93cd-ad41-4711-9513-27b38eb8acb3"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "9bbf9896-3936-47e3-8146-057d38e5d5fb"
export function Links8c6f93cdAd414711951327b38eb8acb3(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links8c6f93cdAd414711951327b38eb8acb3", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "66d6d553-07e2-45c4-9eff-45601938a564"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "0c6ee500-df2d-44fe-9b9a-25dc5e02b0fd"
export function Footer66d6d55307e245c49eff45601938a564(props: any) {
  return (
    <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="Footer66d6d55307e245c49eff45601938a564" {...props} />
  );
}

// id: "4fc8ac3a-ec62-4537-8e40-a3c7307410e4"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a36441a8-eecc-498c-a978-7a3e3f2f698a"
export function Header4fc8ac3aEc6245378e40A3c7307410e4(props: any) {
  return (
    <SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="Header4fc8ac3aEc6245378e40A3c7307410e4" title="Join Us" {...props} />
  );
}

// id: "feaa32e3-b456-46bd-a16d-e751c380f98a"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "9e3af418-fcce-4efe-8b05-5f06ecdab7e0"
export const ImageUrlFeaa32e3B45646bdA16dE751c380f98a = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "78788bc6-f60e-4108-bbcc-177597826487"
// title: ""
// type: :text
// key: "label"
// parent_id: "56966061-b245-431f-b282-9e613baf1e70"
export const Label78788bc6F60e4108Bbcc177597826487 = "Join Us";

// id: "70f31010-0326-4748-b6ed-557c01cff56c"
// title: ""
// type: :text
// key: "title"
// parent_id: "fd40249f-bcdf-41ef-a4dd-3714b898ae45"
export const Title70f3101003264748B6ed557c01cff56c = "About Us";

// id: "69d119a6-b45a-4057-867a-5ff1a83c7f67"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "440adf96-c3b8-4ac7-b2dc-ade2cdb8564b"
export const PostSlug69d119a6B45a4057867a5ff1a83c7f67 = "about-us-posts";

// id: "61750d6a-631f-42a0-9030-f72ac0218e5f"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero61750d6a631f42a09030F72ac0218e5f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero61750d6a631f42a09030F72ac0218e5f hero`}>
      <div className="title">
        {props["title"] || Title88b806972ed64d0a902d8ef9301fc705}
      </div>
    </div>
  );
}

// id: "8b5ee789-7d97-46cf-a7dc-b226afdd042f"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero8b5ee7897d9746cfA7dcB226afdd042f(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero8b5ee7897d9746cfA7dcB226afdd042f hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button56966061B245431fB2829e613baf1e70", parentTag)} label="Join Us" href="/join" {...props} />}
      </div>
    </div>
  );
}

// id: "f6c9d230-d6b0-47a4-967f-c62c3c72448a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "621bd3ee-4417-4c60-80f2-8e9a8cce5f23"
export function FooterF6c9d230D6b047a4967fC62c3c72448a(props: any) {
  return (
    <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="FooterF6c9d230D6b047a4967fC62c3c72448a" {...props} />
  );
}

// id: "ec073b4c-ce4b-4ca6-87e9-c6a63c3e26b0"
// title: ""
// type: :reference
// key: "header"
// parent_id: "1e69228a-ca1d-475f-a948-cfa7b5989cc4"
export function HeaderEc073b4cCe4b4ca687e9C6a63c3e26b0(props: any) {
  return (
    <SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="HeaderEc073b4cCe4b4ca687e9C6a63c3e26b0" title="Secondary Page" {...props} />
  );
}

// id: "fb3aef7c-ec57-4beb-91d2-f13de74bf186"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "19d764af-fef1-4655-a08c-032a19f69b63"
export const ButtonClassFb3aef7cEc574beb91d2F13de74bf186 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "56966061-b245-431f-b282-9e613baf1e70"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "8b5ee789-7d97-46cf-a7dc-b226afdd042f"
export function Button56966061B245431fB2829e613baf1e70(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button56966061B245431fB2829e613baf1e70", parentTag)} label="Join Us" href="/join" {...props} />
  );
}

// id: "eb10f7d3-a365-4f88-a204-447977060110"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "3882e5e0-f9a7-49e0-bed5-1e8867aab018"
export function FooterEb10f7d3A3654f88A204447977060110(props: any) {
  return (
    <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="FooterEb10f7d3A3654f88A204447977060110" {...props} />
  );
}

// id: "a5ea31d9-bf1f-49d7-8570-777a7517abad"
// title: ""
// type: :text
// key: "title"
// parent_id: "13467bbe-b701-4636-9480-940f3eef2555"
export const TitleA5ea31d9Bf1f49d78570777a7517abad = "Secondary Marketing Title 1";

// id: "8711bec9-4508-4a61-92f7-e0f3d3bd3328"
// title: ""
// type: :text
// key: "url"
// parent_id: "eba928c0-aab1-445a-a829-877e57da6136"
export const Url8711bec945084a6192f7E0f3d3bd3328 = "/";

// id: "4d67ba5e-c564-462d-83a0-411fa085deab"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "77c394f6-b78f-4120-aea6-27fca9e1a873"
export const NavMenuSlug4d67ba5eC564462d83a0411fa085deab = "members-primary-nav";

// id: "251de3bc-6482-40ad-9974-4ae2d1887dea"
// title: ""
// type: :reference
// key: "label"
// parent_id: "eba928c0-aab1-445a-a829-877e57da6136"
export function Label251de3bc648240ad99744ae2d1887dea(props: any) {
  return (
    <DefaultLogo9e3af418Fcce4efe8b055f06ecdab7e0 parentTag="Label251de3bc648240ad99744ae2d1887dea" {...props} />
  );
}

// id: "18486a18-903c-470f-90fe-5a359d804203"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader18486a18903c470f90fe5a359d804203(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader18486a18903c470f90fe5a359d804203 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo7ba68e6503624d8a9bb05c51bc9201ec", parentTag)} label={<DefaultLogo9e3af418Fcce4efe8b055f06ecdab7e0 parentTag="LabelFfced2f54c984bacA9028bd592041ed3" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationF7b680a2B3354b3fBae1Dd9003cc561a", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "9bbf9896-3936-47e3-8146-057d38e5d5fb"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter9bbf9896393647e38146057d38e5d5fb(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter9bbf9896393647e38146057d38e5d5fb footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links8c6f93cdAd414711951327b38eb8acb3", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo59fbffc623334f5fA9d4De881be61a8f />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright749c4a1fEdad47d6Adcf83a7944f1c55", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow365be48c281c494cB82e65521475df47", parentTag)} {...props} />}
    </div>
  );
}

// id: "31ea10e2-e9a0-4817-a81a-5b88a4354dd9"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "55b17fdf-5368-485c-a4df-c81a5e4ccfed"
export function WelcomeBack31ea10e2E9a04817A81a5b88a4354dd9(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack31ea10e2E9a04817A81a5b88a4354dd9", parentTag)} {...props} />
  );
}

// id: "f21eb820-eeaf-4af0-9ae9-df26bf4e8a85"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2010b3e3-99ca-4c7d-af05-1c90c66f99cd"
export function HeaderF21eb820Eeaf4af09ae9Df26bf4e8a85(props: any) {
  return (
    <SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="HeaderF21eb820Eeaf4af09ae9Df26bf4e8a85" title="Join Us" {...props} />
  );
}

// id: "82af25a5-9e47-47e9-a53e-6109a116c8be"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "ad4890a7-ac87-4acd-939d-ad759e9410b4"
export function Navigation82af25a59e4747e9A53e6109a116c8be(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation82af25a59e4747e9A53e6109a116c8be", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "59fbffc6-2333-4f5f-a9d4-de881be61a8f"
// title: ""
// type: :html
// key: "logo"
// parent_id: "9bbf9896-3936-47e3-8146-057d38e5d5fb"
export function Logo59fbffc623334f5fA9d4De881be61a8f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} Logo59fbffc623334f5fA9d4De881be61a8f logo`} />
  );
}

// id: "440adf96-c3b8-4ac7-b2dc-ade2cdb8564b"
// title: ""
// type: :reference
// key: "body"
// parent_id: "324587eb-a7db-4098-8d9a-a34fcbd5a18e"
export function Body440adf96C3b84ac7B2dcAde2cdb8564b(props: any) {
  return (
    <MarketingBody5a5c272e306d4d2a9c4051a0ca5cf2de parentTag="Body440adf96C3b84ac7B2dcAde2cdb8564b" postSlug="about-us-posts" {...props} />
  );
}

// id: "163a63ef-3f19-473a-bfcc-0520a2638ee5"
// title: ""
// type: :text
// key: "href"
// parent_id: "56966061-b245-431f-b282-9e613baf1e70"
export const Href163a63ef3f19473aBfcc0520a2638ee5 = "/join";

// id: "d38c5174-38c6-4856-940b-3d147b1f7206"
// title: ""
// type: :text
// key: "class"
// parent_id: "eba928c0-aab1-445a-a829-877e57da6136"
export const ClassD38c517438c64856940b3d147b1f7206 = "linked-logo";

// id: "f269859b-2d19-49c5-911e-aca9ee2680dd"
// title: ""
// type: :text
// key: "title"
// parent_id: "b7665883-a756-433d-a866-a4844be893ad"
export const TitleF269859b2d1949c5911eAca9ee2680dd = "Resources";

// id: "13467bbe-b701-4636-9480-940f3eef2555"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader13467bbeB70146369480940f3eef2555(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 parentTag="HeaderTop41d7c264C8a04fff8425A823412432c9" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero61750d6a631f42a09030F72ac0218e5f parentTag="Hero55c4c59eF9254648Ad4086f86966e638" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "77c394f6-b78f-4120-aea6-27fca9e1a873"
// title: ""
// type: :reference
// key: "header"
// parent_id: "0c6ee500-df2d-44fe-9b9a-25dc5e02b0fd"
export function Header77c394f6B78f4120Aea627fca9e1a873(props: any) {
  return (
    <MembersAreaHeaderE622cfd71d7240399e3aC7ef2f3fcc0c parentTag="Header77c394f6B78f4120Aea627fca9e1a873" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "021f0309-bc00-44a7-8889-584fcb339298"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "19d764af-fef1-4655-a08c-032a19f69b63"
export const PostSlug021f0309Bc0044a78889584fcb339298 = "home-page-posts2";

// id: "6840fc93-9fc6-49a6-a053-03d9151ca68d"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "621bd3ee-4417-4c60-80f2-8e9a8cce5f23"
export function SecondaryNav6840fc939fc649a6A05303d9151ca68d(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav6840fc939fc649a6A05303d9151ca68d", parentTag)} {...props} />
  );
}

// id: "823fa778-b91c-459d-929c-53439da4107f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "3882e5e0-f9a7-49e0-bed5-1e8867aab018"
export function Header823fa778B91c459d929c53439da4107f(props: any) {
  return (
    <SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 parentTag="Header823fa778B91c459d929c53439da4107f" title="Public Events" {...props} />
  );
}

// id: "88b80697-2ed6-4d0a-902d-8ef9301fc705"
// title: ""
// type: :text
// key: "title"
// parent_id: "61750d6a-631f-42a0-9030-f72ac0218e5f"
export const Title88b806972ed64d0a902d8ef9301fc705 = "Default Title";

// id: "5a16935b-e327-487f-9693-81f9d45f8761"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "e967d8c7-24e6-4625-af4a-0d2ea78d7a8a"
export function Footer5a16935bE327487f969381f9d45f8761(props: any) {
  return (
    <DefaultFooter9bbf9896393647e38146057d38e5d5fb parentTag="Footer5a16935bE327487f969381f9d45f8761" {...props} />
  );
}

// id: "2c6a84a8-97d4-4294-b2f1-7cf668ea3ba4"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "b4189e5d-0573-4c19-98da-b733fc8e9503"
export const WidgetId2c6a84a897d44294B2f17cf668ea3ba4 = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "9a6761de-fb6b-4b42-8bb4-a08fd770a3aa"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "b77ab5b9-e649-4c2c-a39e-9430bf6c5ffe"
export const ContentSlug9a6761deFb6b4b428bb4A08fd770a3aa = "home-page-quote";

// id: "270669b9-d387-4185-9263-6a10145b7883"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "0a83dafb-347d-4c2b-8dcc-797ef976d002"
export const PostSlug270669b9D387418592636a10145b7883 = "home-page-posts2";

// id: "58ad95dc-65f1-4540-bac2-a6ce25be6f77"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "f7b680a2-b335-4b3f-bae1-dd9003cc561a"
export const NavMenuSlug58ad95dc65f14540Bac2A6ce25be6f77 = "members-primary-nav";

const Components = {
  MarketingCards19d764afFef14655A08c032a19f69b63,
  HomePage55b17fdf5368485cA4dfC81a5e4ccfed,
  Header791bd59f378d4bedA19a82229ae11ce4,
  HeroB4189e5d05734c1998daB733fc8e9503,
  Copyright749c4a1fEdad47d6Adcf83a7944f1c55,
  FooterD389f6f85c4b42a98d7eF30384fccea2,
  HeaderB7665883A756433dA866A4844be893ad,
  Groupflow365be48c281c494cB82e65521475df47,
  WidgetIdB7d4077d3e2e4bec9d1dFca696a6f354,
  Title479ae2d1E57644e1A1f37cc0c39534dd,
  TitleE773c6c3F1044e54A90aE9d40a5f3cef,
  Header2e7f351c2da9418eA1bd0eca05b2fa25,
  Header7eb10533298148ec858f20121926a5ca,
  LinkedLogo7ba68e6503624d8a9bb05c51bc9201ec,
  NavigationF7b680a2B3354b3fBae1Dd9003cc561a,
  NavMenuSlug56b86aca51dd4686A256771844a3b750,
  NavMenuSlug969e63d5548847708854F50e8929ae56,
  NavMenuSlug855d986b165b4e388ebbB521555bbe28,
  Title2f6c345843df45388ebbA7e964f51f14,
  HomeHeader670e8f4bA00447bc936dF6b6edad028d,
  FooterC4b60f40358f4b528d83272df8619aff,
  LinkedLogoEba928c0Aab1445aA829877e57da6136,
  TitleF297a15190764aa1A27c0bdcba56e9a4,
  BackgroundImage88ebf6a60cab42bf841184e61b48bee3,
  HeaderTop7d0f816aA16f45ec89b65f258df7e190,
  QuoteB77ab5b9E6494c2cA39e9430bf6c5ffe,
  TitleA718045d31334dedBa57Fdae18874478,
  Title3d6d2253D73d4553Ab7290d8c4a730fd,
  LabelFfced2f54c984bacA9028bd592041ed3,
  AdminPages805f517a2e044b42Bc2e3bebb0b4741b,
  Label70c8e0f7789d487a87bd9c211e71107b,
  TitleC4b60145541a4544B0b303c31be84010,
  ResourcesPage0d95b6d03675449d8dcf382036c93f6a,
  SignupPages2010b3e399ca4c7dAf051c90c66f99cd,
  MessagePages96b75e7cF4ce43f7A580A3d19425300b,
  Hero55c4c59eF9254648Ad4086f86966e638,
  Footer24d2ed2b543f4ed6847a6a537b6c126c,
  HeaderBe28f811C2d04e9c9ffaE69074b809c2,
  MarketingCards0a83dafb347d4c2b8dcc797ef976d002,
  HeaderTop41d7c264C8a04fff8425A823412432c9,
  AltC5c2d5d7B00c4693Adb8946345203112,
  HeaderTopB268625f30114ad1BadfFcdf26d15be9,
  Class108e0bd8Dcaf4fbeAc0361cddc7ba9a5,
  NavMenuSlug94c344d34511487aAbc220ea52531648,
  Title4d3941caD92e40a6Bb18D1ba66b0364b,
  DefaultHeader9be75641Ddb74489Ac039be0fa4ab7f9,
  MemberPages621bd3ee44174c6080f28e9a8cce5f23,
  HeaderC953435f097744d48afe47bc1d58ee59,
  MembersAreaHeaderE622cfd71d7240399e3aC7ef2f3fcc0c,
  DefaultLogo9e3af418Fcce4efe8b055f06ecdab7e0,
  MarketingBody5a5c272e306d4d2a9c4051a0ca5cf2de,
  PageQuote8c0380c0860742ac97baB186e4b3daf7,
  PublicEventsPage3882e5e0F9a749e0Bed51e8867aab018,
  AboutUsPage324587ebA7db40988d9aA34fcbd5a18e,
  JoinUsPageA36441a8Eecc498cA9787a3e3f2f698a,
  MembersAreaDefault0c6ee500Df2d44fe9b9a25dc5e02b0fd,
  Href7392c0c43d9c4a2dBb1575fbece0d063,
  DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4,
  BodyF8af7e59325f42c68556Fd472f5a96fd,
  BackgroundImage5d0bbaed9274411087b9914645e10233,
  HeaderFd40249fBcdf41efA4dd3714b898ae45,
  SecondaryPage1e69228aCa1d475fA948Cfa7b5989cc4,
  FooterF5a09dca98a542b8B563F864ceaf59e3,
  AboutLevelingPageE967d8c724e64625Af4a0d2ea78d7a8a,
  Links8c6f93cdAd414711951327b38eb8acb3,
  Footer66d6d55307e245c49eff45601938a564,
  Header4fc8ac3aEc6245378e40A3c7307410e4,
  ImageUrlFeaa32e3B45646bdA16dE751c380f98a,
  Label78788bc6F60e4108Bbcc177597826487,
  Title70f3101003264748B6ed557c01cff56c,
  PostSlug69d119a6B45a4057867a5ff1a83c7f67,
  SecondaryMarketingHero61750d6a631f42a09030F72ac0218e5f,
  HomePageHero8b5ee7897d9746cfA7dcB226afdd042f,
  FooterF6c9d230D6b047a4967fC62c3c72448a,
  HeaderEc073b4cCe4b4ca687e9C6a63c3e26b0,
  ButtonClassFb3aef7cEc574beb91d2F13de74bf186,
  Button56966061B245431fB2829e613baf1e70,
  FooterEb10f7d3A3654f88A204447977060110,
  TitleA5ea31d9Bf1f49d78570777a7517abad,
  Url8711bec945084a6192f7E0f3d3bd3328,
  NavMenuSlug4d67ba5eC564462d83a0411fa085deab,
  Label251de3bc648240ad99744ae2d1887dea,
  ShortHeader18486a18903c470f90fe5a359d804203,
  DefaultFooter9bbf9896393647e38146057d38e5d5fb,
  WelcomeBack31ea10e2E9a04817A81a5b88a4354dd9,
  HeaderF21eb820Eeaf4af09ae9Df26bf4e8a85,
  Navigation82af25a59e4747e9A53e6109a116c8be,
  Logo59fbffc623334f5fA9d4De881be61a8f,
  Body440adf96C3b84ac7B2dcAde2cdb8564b,
  Href163a63ef3f19473aBfcc0520a2638ee5,
  ClassD38c517438c64856940b3d147b1f7206,
  TitleF269859b2d1949c5911eAca9ee2680dd,
  SecondaryMarketingHeader13467bbeB70146369480940f3eef2555,
  Header77c394f6B78f4120Aea627fca9e1a873,
  PostSlug021f0309Bc0044a78889584fcb339298,
  SecondaryNav6840fc939fc649a6A05303d9151ca68d,
  Header823fa778B91c459d929c53439da4107f,
  Title88b806972ed64d0a902d8ef9301fc705,
  Footer5a16935bE327487f969381f9d45f8761,
  WidgetId2c6a84a897d44294B2f17cf668ea3ba4,
  ContentSlug9a6761deFb6b4b428bb4A08fd770a3aa,
  PostSlug270669b9D387418592636a10145b7883,
  NavMenuSlug58ad95dc65f14540Bac2A6ce25be6f77
}

export default Components;